@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".NavigationList";

#{$selector} {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  &::after {
    position: absolute;
    top: -20px;
    right: -25%;
    z-index: -1;
    width: 60%;
    height: calc(100% + 40px);
    background: $background-primary;
    background: linear-gradient(24deg, $background-primary 0%, $background-secondary 45%);
    transform: skewX(-15deg);
    animation: reveal 1s forwards;
    content: "";

    @include screenSmallerThan($screen-xl) {
      opacity: 0;
    }
  }

  @include screenSmallerThan($screen-xl) {
    background: linear-gradient(24deg, $background-primary 0%, $background-secondary 45%);
  }
}

@keyframes reveal {
  from {
    clip-path: inset(0 0 0 100%);
  }

  to {
    clip-path: inset(0 0 0 0);
  }
}
