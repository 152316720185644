@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".EditCoExhibitorDialog";

#{$selector} {
  &__description {
    font-size: 0.9em;
  }
}
