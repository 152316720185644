@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopOverviewStandSelectionDialog";

#{$selector} {
  &__info {
    @include text("meta");
    padding-bottom: $space-md;
  }

  &__desc {
    @include text("meta");
    padding-bottom: $space-lg;
  }

  &__helperText {
    @include text("base");
  }

  &__loading {
    display: flex !important;
    align-content: center;
    justify-content: center;
    margin: auto;
  }

  &__disableButton {
    font-weight: 700 !important;
  }
}
