@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".LazyImage";

#{$selector} {
  width: "100%";
  height: "auto";
  animation: fade-in 0.5s;
}

@keyframes fade-in {
  0% { opacity: 0; }

  100% { opacity: 1; }
}
