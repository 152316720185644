@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
/* stylelint-disable */
@import "node-reset-scss/scss/reset";
/* stylelint-enable */

/* stylelint-disable selector-max-id */
/* stylelint-disable selector-id-pattern */

#__next {
  height: 100%;
}

/* stylelint-enable selector-max-id */

html,
body {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0;
  /* Prevent iOS font scaling in landscape: */
  background: $background-base;
  -moz-osx-font-smoothing: grayscale;
  /* Allow better font smoothing: */
  -webkit-font-smoothing: antialiased;
  /* Allow better font smoothing: */
  text-size-adjust: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.is-horizontal {
  display: flex;
}

.is-content-justification-space-between {
  justify-content: space-between;
}

.is-horizontal.button-wrapper > div {
  margin-right: $space-lg;
}
