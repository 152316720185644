@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".AdvancedFormFieldTreeView";

#{$selector} {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__chips {
    margin-top: $space-lg;
  }
}
