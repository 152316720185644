@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".AdvancedFormTitle";

#{$selector} {
  margin-bottom: $space-xl;
  padding-left: $space-lg;
  border-left: 3px solid $border-primary;

  &__headline {
    @include text("headline/lg");
    margin-bottom: $space-md;
    line-height: 22px !important;
  }

  &__description {
    @include text("base");
  }
}
