@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".AdvancedFormStep";

#{$selector} {
  &__forms {
    margin-top: $space-lg;
  }

  &__form {
    margin-top: $space-xl;

  }
}
