@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".TableRow";

#{$selector} {
  &__icon {
    transform: rotate(-90deg);
  }

  &--has-link {
    cursor: pointer;
  }

  &__first__column {
    word-break: break-word;
  }

  &__table__cell {
    word-break: break-word;
  }
}
