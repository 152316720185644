@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".BookingDetailFileManagement";

#{$selector} {
  &__title__wrapper {
    display: flex;
  }

  &__title {
    @include text("component/documentBox/headline");
    display: block;
    margin-bottom: $space-lg;
  }

  &__title__icon {
    padding-right: $space-md;
  }

  &__icon {
    margin-top: $space-xs;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    margin-left: calc($space-md - 2px);
    padding-left: $space-md;
    border-left: 3px solid $border-primary;
  }

  &__file__wrapper {
    position: relative;
    box-sizing: border-box;
    width: calc(25% - ($space-md * 2));
  }

  &__file {
    @include text("component/card");
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: $space-lg $space-md 0 $space-md;
    padding: $space-lg $space-xl;
    background: rgba($background-gray, 0.2);
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;

    &__title {
      display: inline-block;
      width: 80%;
      padding-bottom: $space-xs;
      overflow: hidden;
      white-space: nowrap;
      text-align: center;
      text-overflow: ellipsis;
    }

    &__delete {
      position: absolute;
      top: 13px;
      right: 13px;
      z-index: 1;
      padding: $space-md $space-xs $space-xs $space-md;
      background: $background-base;
      border-width: 0;
      border-top-left-radius: 50px;
      transform: rotate(-90deg);
      cursor: pointer;
    }

    &__icon__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: $space-sm;
      padding: $space-xs $space-sm;
      border-radius: 5px;
    }

    &__icon {
      fill: $icon-primary;
    }

    &__size {
      font-size: 0.9em;
      opacity: 0.75;
    }

    @include when-hovered {
      color: $text-white;
      background: $background-primary;

      &__icon {
        fill: $icon-white !important;
      }

      &__size {
        color: $text-white !important;
      }
    }
  }
}
