@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Footer";

#{$selector} {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: $space-lg;
  background-color: var(--footer-background);
  border-top: 1px solid var(--footer-border-top-color);

  @include screenLargerThan($screen-xl) {
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: $space-xl;
  }

  @include screenSmallerThan($screen-xl) {
    padding-top: $space-lg;
  }

  &__column {
    padding: 0 $space-2xl 0 $space-2xl;
  }

  &__column__left,
  &__column__right {
    display: flex;
  }

  &__column__left {
    flex-wrap: wrap;
    justify-content: flex-start;

    #{$selector}__column {
      flex: 50%;
      margin-bottom: $space-md;

      @include screenLargerThan($screen-lg) {
        flex: auto;
        margin-bottom: 0;
      }
    }

    @include screenLargerThan($screen-lg) {
      flex-wrap: nowrap;
    }
  }

  &__column__right {
    justify-content: flex-end;
  }

  &__column--is-last {
    width: 100%;
    margin-right: 0;

    @include screenLargerThan($screen-xl) {
      width: auto;
    }

    @include screenSmallerThan($screen-xl) {
      margin-bottom: $space-lg;
    }
  }

  &__logo {
    position: relative;
    z-index: $layer-footer;
    max-height: 20px;
    margin-top: $space-md;
    margin-bottom: $space-2xl;
    color: $ui-footer-text;

    &__image {
      width: 130px;
    }

    @include screenLargerThan($screen-xl) {
      margin-top: $space-lg;
      padding-top: $space-xs;
    }
  }

  &__navigation {
    position: relative;
    z-index: $layer-footer;

    &__headline {
      @include text("footer/headline");
      margin: $space-lg 0;
      overflow: hidden;
      color: $ui-footer-text;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include screenLargerThan($screen-xl) {
        margin-top: $space-xl;
        margin-bottom: $space-2xl;
      }
    }

    &__item__label {
      @include text("navigation/xs");
      color: $ui-footer-text;
      opacity: 0.75;

      &:hover {
        color: $text-primary;
      }
    }
  }

  &__social__wrapper {
    display: block;
    flex-direction: row;
    align-items: center;
    align-self: center;

    @include screenLargerThan($screen-md) {
      display: flex;
    }
  }

  &__social__icons {
    position: relative;
    z-index: $layer-footer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: $space-lg 0 $space-md 0;
    margin-right: $space-md;

    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      min-width: 30px;
      height: 30px;
      margin-right: $space-lg;
      text-decoration: none;
      border-radius: 30px;
    }
  }

  &__partner__logos {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: $space-xl $space-lg;

    &__image {
      width: 140px;
      padding-left: $space-lg;
    }

    @include screenLargerThan($screen-xl) {
      justify-content: flex-end;
    }
  }

  &__copyright {
    @include text("meta");
    position: relative;
    bottom: 10px;
    z-index: $layer-footer;
    display: block;
    width: 100%;
    padding-top: $space-xl;
    color: $ui-footer-text;
    text-align: center;
    opacity: 0.75;
  }

  &__overlay {
    @include screenLargerThan($screen-xl) {
      position: absolute;
      bottom: 0;
      left: -10%;
      z-index: 1;
      width: 80%;
      height: 70%;
      transform: skewX(45deg);
    }
  }

  &--is-small {
    align-items: center;
    min-height: 70px;
    padding: $space-lg;
    padding-top: $space-lg;

    #{$selector}__column {
      padding-top: 0;

      &-is-last {
        margin-right: 0;
      }

      @include screenSmallerThan($screen-xl) {
        padding-top: $space-lg;
      }
    }

    #{$selector}__logo {
      margin-top: 0;
      margin-bottom: 0;

      @include screenSmallerThan($screen-xl) {
        text-align: center;
      }
    }

    #{$selector}__social__icons {
      margin-top: 0;
      margin-bottom: 0;
    }

    #{$selector}__navigation {
      display: block;
      justify-content: center;

      &__item {
        margin-right: $space-xl;
      }

      @include screenLargerThan($screen-md) {
        display: flex;
      }
    }

    #{$selector}__copyright {
      padding-top: $space-lg;

      @include screenLargerThan($screen-xl) {
        bottom: auto;
        padding-top: 0;
      }
    }
  }
}
