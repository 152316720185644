@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".RejectPlacementDialog";

#{$selector} {
  &__title {
    color: $text-error !important;
  }

  &__content {
    @include text("base");
  }

  &__form {
    padding-top: $space-lg;
  }
}
