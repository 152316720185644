@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Dialog";

#{$selector} {
  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__buttonRow {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
