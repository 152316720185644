@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".PermissionTableActions";

#{$selector} {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: $space-lg;

  &__action {
    @include text("link");
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0;
    background: none;
    border: none;
    cursor: pointer;

    &--primary {
      color: $text-primary;
    }
  }
}
