@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Link";

#{$selector} {
  color: $text-base;
  text-decoration: none;
  cursor: pointer;

  &__label {
    @include text("link");
  }

  @include when-hovered {
    color: $text-primary;
    text-decoration: underline;
  }

  &--primary {
    color: $text-primary;

    @include when-hovered {
      color: $text-base;
    }
  }

  &--secondary {
    color: $text-secondary;
  }

  &--white {
    color: $text-white;
  }
}
