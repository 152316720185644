@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".AddCoExhibitorEmailInvite";

#{$selector} {
  &__button {
    float: left;
  }

  &_radio {
    float: left;
  }

  &__description {
    @include text("base");
    margin-bottom: $space-lg;
  }
}
