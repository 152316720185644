@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopOverviewStandRequestDialog";

#{$selector} {
  &__desc {
    @include text("meta");
    padding-bottom: $space-lg;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}
