@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".DeleteFileDialog";

#{$selector} {
  &__content {
    @include text("base");
  }
}
