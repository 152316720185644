@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Accordion";

#{$selector} {
  &__tab {
    padding-right: $space-md;

    &__headline {
      @include text("headline/md");
      display: flex;
      flex-direction: row;
      color: $text-primary;
    }

    &__description {
      @include text("meta");
    }
  }
}
