@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Avatar";

#{$selector} {
  &__container {
    text-decoration: none;
    background-color: $background-primary;
  }

  &__icon {
    width: 75%;
    height: 75%;
  }
}
