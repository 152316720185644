@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".BookingDetailDropdown";

#{$selector} {
  margin-top: $space-sm;

  &__button {
    display: flex;
    align-items: center;
    text-align: right;
  }

  &__dropdown {
    width: 260px;
    margin-left: 0 !important;
    list-style-type: none !important;
  }

  &__dropdown__item {
    display: flex;
    align-items: center;
    padding: $space-sm $space-md;
    border-bottom: 1px solid rgba($background-gray, 0.5);

    &:last-child {
      border-bottom: none;
    }

    &__icon {
      margin-right: $space-md;
      color: $icon-primary;
    }

    @include when-hovered {
      background: rgba($background-gray, 0.5);
    }
  }

  &__print__view {
    display: none;
  }
}
