@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".InputField";

#{$selector} {
  &__suffix {
    margin-top: $space-lg;
  }

  &__helperText__wrapper {
    margin-bottom: 0 !important;
  }

  &__helperText {
    display: flex;

    &__content {
      width: 75%;
      padding-bottom: $space-md;
    }

    &__legend {
      width: 25%;
      text-align: right;
    }
  }
}
