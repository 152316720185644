@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopOrderDetailsCancelOrderDialog";

#{$selector} {
  display: flex;
  flex-direction: column;

  &__description {
    @include text("meta");
    margin-bottom: $space-lg;
  }

  &__reason {
    margin: $space-lg 0 !important;
  }

  &__listitem {
    display: flex;
    flex-direction: column;
    margin: $space-md 0 0 0;

    & > * {
      width: 100%;
    }

    &__content {
      display: flex;
      font-size: small;

      & > div {
        width: 80%;
      }

      &__all {
        @include text("meta");
        padding: $space-md;
        font-weight: bold;
        font-size: small;
      }

      &__container {
        display: flex;
      }

      &__image {
        position: relative;
        display: flex;
        width: 50px;
        min-width: 50px;
        margin-right: $space-xl;
        padding: $space-sm;

        &--is-disabled {
          opacity: 0.4;
        }

        & > * {
          position: relative !important;
          width: 35px !important;
          height: 35px !important;
          margin-right: $space-md;
        }
      }

      &__detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 25%;
        font-size: small;

        &--is-disabled {
          opacity: 0.4;
        }

        &__name {
          @include text("meta");
          font-weight: bold;
        }

        &__price {
          @include text("meta");
          display: flex;
          justify-content: space-between;
        }

        &__status {
          display: flex;
          justify-content: flex-end;
          width: 100%;
        }
      }
    }
  }

  &__divider {
    width: 100%;
    margin: $space-md 0 0 0 !important;
  }
}
