// z-index properties
// -----------------------------------------------------------------------------

$layer-header: 2;
$layer-footer: 2;

$layer-navigation: 999;
$layer-navigation-dropdown: 101;
$layer-navigation-close: 102;

$layer-dropdown: 998;
