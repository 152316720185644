@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Content";

#{$selector} {
  margin-bottom: $space-lg;

  &__title,
  &__content {
    @include text("base");
    display: block;
    padding-bottom: $space-lg;
    text-align: left;
  }

  &__title {
    padding-top: $space-md;
    font-size: 1rem;
  }

  &__content {
    padding: $space-md;
    border: 1px solid $border-lightgray;
    border-radius: 5px;
  }

  &__helperText {
    @include text("base");
    display: block;
    margin-left: $space-lg !important;
    padding-top: $space-sm !important;
    color: rgba(0 0 0 / 60%);
    font-size: 0.75rem;
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }
}
