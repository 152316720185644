@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Stepper";

#{$selector} {
  &__label {
    cursor: pointer !important;
  }

  &__steps {
    flex-wrap: wrap;
  }
}
