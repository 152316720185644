@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Dropdown";

#{$selector} {
  position: relative;

  &__content {
    position: absolute;
    right: 0;
    z-index: $layer-dropdown;
    background: $background-base;
    border-radius: $ui-default-border-radius;
    box-shadow: $ui-elevation-dropdown;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;

    &--is-open {
      visibility: visible;
      opacity: 1;
    }
  }

  &__button {
    background: none;
    border: none;
    cursor: pointer;
  }

  &__dropdown {
    width: 200px;
    margin-left: 0 !important;
    list-style-type: none !important;
  }

  &__dropdown__button {
    & > * {
      display: flex;
      align-items: center;
      text-align: right;
    }
  }

  &__dropdown__item {
    display: flex;
    align-items: center;
    padding: $space-sm $space-md;
    border-bottom: 1px solid rgba($background-gray, 0.5);

    &:last-child {
      border-bottom: none;
    }

    &--is-disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    &__icon {
      display: flex;
      margin-right: $space-md;
      color: $icon-primary;
    }

    @include when-hovered {
      background: rgba($background-gray, 0.5);
    }
  }
}
