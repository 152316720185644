@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".DataTable";

#{$selector} {
  &__headline {
    margin-top: $space-xl;
  }

  &__item__row {
    display: flex;
    margin-top: $space-lg;
    padding: $space-lg 0;
  }

  &__item {
    width: 25%;

    &__label,
    &__value {
      display: block;
      margin: 1%;
      word-break: break-word;
    }
  }
}
