/* stylelint-disable */
#main {
  .has-text-align-left {
    text-align: left;
  }

  .has-text-align-center {
    text-align: center;
  }

  .has-text-align-right {
    text-align: right;
  }

  p {
    margin-bottom: $space-lg;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    img {
      margin: $space-md 0;
    }
  }

  sup {
    vertical-align: super;
  }

  sub {
    vertical-align: sub;
  }

  kbd {
    font-family: monospace;
  }

  code {
    padding: $space-xs;
    font-family: monospace;
    background: $background-gray;
    border-left: 2px solid $border-primary;
  }

  ul {
    margin-left: $space-xl;
    list-style-type: disclosure-closed;
  }

  ol {
    margin-left: $space-xl;
    list-style-type: decimal;
  }

  .wp-block-quote,
  .wp-block-pullquote {
    margin-bottom: $space-lg;
    padding-left: $space-sm;
    border-left: 3px solid $border-primary;

    p {
      margin-bottom: $space-sm;
    }

    cite {
      font-style: italic;
      opacity: 0.8;
    }
  }

  .wp-block-pullquote {
    border-left: none;

    &::before {
      display: inline-block;
      width: 20px;
      height: 20px;
      padding: 17px 24px 20px 14px;
      color: $text-primary;
      font-size: 2em;
      border: 2px solid $text-primary;
      border-radius: 100px;
      content: "“";
    }
  }

  .wp-block-table {
    figcaption {
      @include text("meta");
      padding-top: $space-md;
    }

    table {
      border: 2px solid $border-lightgray;
      border-collapse: collapse;

      td,
      th {
        padding: $space-md;
        border: 1px solid #ffffff;
      }

      tbody {
        width: 100%;
      }

      tr:nth-child(even) {
        background: #d0e4f5;
      }
    }
  }

  .wp-block-image,
  .wp-block-cover {
    img {
      width: 100%;
    }
  }

  .wp-block-cover {
    .wp-block-cover__background {
      background: $background-primary;
      opacity: 0.5;
    }

    .wp-block-cover__inner-container {
      color: $text-white;
    }
  }

  .wp-block-file {
    a.wp-block-file__button {
      padding: $space-xs;
      color: $text-primary;
      background: none;
      border: 1px solid $border-primary;
      border-radius: 4px;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  .wp-block-button {
    .wp-block-button__link {
      color: $text-white;
      text-decoration: none;
      background: $background-primary;
      border: 1px solid $border-primary;
      border-radius: 4px;
      padding: $space-md;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      margin-right: $space-xs;

      &:hover {
        color: $text-primary;
        background: none;
      }
    }

    &.is-style-outline .wp-block-button__link {
      color: $text-primary;
      background: none;

      &:hover {
        color: $text-white;
        background: $background-primary;
      }
    }
  }

  .wp-block-media-text__media {
    margin-right: $space-sm;
  }

  .wp-block-media-text__content {
    padding-left: 0;
  }

  .wp-block-group.is-nowrap {
    display: flex;
  }

  .wp-block-buttons {
    margin-top: $space-lg;
  }

  .alignright {
    text-align: right;

    table {
      float: right;
    }

    figcaption {
      float: right;
      width: 100%;
      text-align: right;
    }
  }

  .aligncenter {
    margin-right: auto;
    margin-left: auto;
    text-align: center;

    table {
      text-align: left;
    }

    figcaption {
      width: 100%;
      text-align: center;
    }
  }

  .alignfull {
    width: 100%;

    a,
    & > div,
    table,
    iframe {
      width: 100%;
    }
  }
}
/* stylelint-enable */