@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Navigation";

#{$selector} {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $layer-navigation;
  width: 100%;
  height: 100%;
  padding: $space-lg;
  background-color: rgb(255 255 255 / 80%);
  opacity: 1;
  transition: background-color 1s linear;

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: $layer-navigation-close;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    padding: $space-xs;
    background-color: $background-base;
    border-radius: 50px;
    cursor: pointer;

    &__icon {
      width: 22px;
      fill: $icon-secondary;
    }
  }

  &__dropdown {
    @include screenSmallerThan($screen-xl) {
      display: none;
    }
  }

  @include screenSmallerThan($screen-xl) {
    padding: 0;
  }
}
