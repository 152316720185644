@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".BookingDetailCoExhibitorTable";

#{$selector} {
  margin-top: $space-2xl;

  &__headline {
    margin-bottom: $space-lg;
  }
}
