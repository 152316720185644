@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".NavigationListItem";

#{$selector} {
  z-index: $layer-navigation;
  align-self: flex-end;
  width: 30%;
  padding-right: $space-md;
  padding-bottom: $space-xl;
  text-align: right;
  opacity: 1;
  transition: all 2s ease-in;
  animation: fade-in 2s;

  @include screenSmallerThan($screen-xl) {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    padding-bottom: 0;
  }

  &__label {
    @include text("navigation/xl");
    color: $ui-navigation-text;
    cursor: pointer;

    @include when-hovered {
      opacity: 0.5;
    }
  }

  &__dropdown {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @include screenLargerThan($screen-xl) {
      display: none;
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
