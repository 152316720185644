@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".BookingDetailPrint";

#{$selector} {
  margin: $space-lg;
  color: #4d4d4d;
  font-weight: normal;
  font-size: 1.1em;
  font-family: Lato, arial, sans-serif;
  line-height: 1.28em;

  &__logo {
    position: relative;
    width: 150px;
    margin-top: $space-lg;
    margin-right: $space-lg;
    margin-bottom: $space-lg;
  }

  &__headline__h1 {
    margin-bottom: 1.5rem;
    color: #000000;
    font-weight: 900;
    font-size: 1.9em;
    font-family: Lato, arial, sans-serif;
    line-height: 1.28em;
  }

  &__headline__h3 {
    margin-bottom: 1.5rem;
    color: #000000;
    font-weight: 700;
    font-size: 1.6em;
    font-family: Lato, arial, sans-serif;
    line-height: 1.28em;
  }

  &__headline__h5 {
    margin-bottom: 1.5rem;
    color: #000000;
    font-weight: normal;
    font-size: 1.3em;
    font-family: Lato, arial, sans-serif;
    line-height: 1.28em;
  }

  &__info {
    margin-bottom: $space-xl;

    &__table {
      margin-bottom: $space-lg;

      &__label,
      &__value {
        font-weight: normal;
        font-size: 0.8em;
        font-family: Lato, arial, sans-serif;
        line-height: 1.28em;
      }

      &__value {
        padding-top: $space-md;
      }
    }
  }

  &__co__exhibitors__column {
    font-size: 11px !important;
  }
}
