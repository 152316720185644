@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".NavigationDropdownItem";

#{$selector} {
  padding-bottom: $space-md;
  text-align: left;

  &__link {
    text-decoration: none;
  }

  &__label {
    color: $text-secondary;

    @include when-hovered {
      color: $text-primary;
    }

    @include screenSmallerThan($screen-xl) {
      color: $ui-navigation-text;

      @include when-hovered {
        color: $ui-navigation-text;
        opacity: 0.5;
      }
    }
  }

  @include screenSmallerThan($screen-xl) {
    @include text("navigation/lg");
  }
}
