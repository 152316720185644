@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Chips";

#{$selector} {
  margin-left: $space-md !important;

  &__chip__wrapper {
    margin: $space-sm 0 $space-sm $space-sm;
  }
}
