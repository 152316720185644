@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopOrderDetailsItemStatus";

#{$selector} {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;

  @include screenSmallerThan($screen-md) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__chip_cancellation,
  &__chip_complaint {
    @include text("meta");
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
    font-size: small;
    white-space: nowrap;
    border-radius: 25px;
  }

  &__chip_cancellation {
    color: $text-error;
    border: 1px solid $text-error;
  }

  &__chip_complaint {
    color: $text-info;
    border: 1px solid $text-info;
  }

  &__small {
    font-size: smaller;
  }
}
