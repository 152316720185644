@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Table";

#{$selector} {
  &__search {
    width: 25%;
    margin-bottom: $space-md;
  }

  &__table__cell {
    color: $text-base;
  }

  // TABLE DYNAMIC STATUS COLORS

  &__table__cell--status {
    &-new {
      color: $ui-table-status-new;
    }

    &-pending {
      color: $ui-table-status-pending;
    }

    &-canceled {
      color: $ui-table-status-canceled;
    }

    &-deferred {
      color: $ui-table-status-deferred;
    }

    &-internal {
      color: $ui-table-status-internal;
    }

    &-released {
      color: $ui-table-status-released;
    }

    &-waiting {
      color: $ui-table-status-waiting;
    }

    &-checked {
      color: $ui-table-status-checked;
    }

    &-placed {
      color: $ui-table-status-placed;
    }

    &-proposalCreated {
      color: $ui-table-status-proposalCreated;
    }

    &-proposalSent {
      color: $ui-table-status-proposalSent;
    }

    &-proposalRejected {
      color: $ui-table-status-proposalRejected;
    }

    &-proposalAssumed {
      color: $ui-table-status-proposalAssumed;
    }

    &-support {
      color: $ui-table-status-support;
    }

    &-invoiceReady {
      color: $ui-table-status-invoiceReady;
    }

    &-invoicing {
      color: $ui-table-status-invoicing;
    }

    &-invoiceCreated {
      color: $ui-table-status-invoiceCreated;
    }

    &-incomingPayment {
      color: $ui-table-status-incomingPayment;
    }

    &-closed {
      color: $ui-table-status-closed;
    }
  }

  &__pargination p {
    margin: 0 !important;
  }
}
