@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".PermissionTable";

#{$selector} {
  padding-top: $space-lg;

  &__booking {
    &__select {
      margin-bottom: $space-sm;
    }
  }

  &__nodata {
    @include text("base");
    margin: $space-lg;
    font-weight: bold;
    font-size: small;
  }
}
