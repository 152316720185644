@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".AdvancedForm";

#{$selector} {
  padding-top: $space-xl;

  &__form {
    margin-top: $space-xl;
  }
}
