@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".PermissionTableActionsAddUserDialog";

#{$selector} {
  &__form {
    min-width: 300px;

    &__field {
      margin-top: $space-lg;
    }
  }
}
