@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".BookingDetailPlacement";

#{$selector} {
  &__accordion {
    margin: 0 !important;
    box-shadow: none !important;

    &::before {
      content: none !important;
    }

    &__summary {
      padding: 0 !important;
    }

    &__details {
      padding: 0 $space-md !important;

      &--is-disabled {
        opacity: 0.5;
      }
    }
  }

  &__title__wrapper {
    display: flex;
    align-items: center;
  }

  &__title {
    @include text("service/detail/placements/title");
    width: 100%;

    &--is-disabled {
      opacity: 0.5;
    }
  }

  &__title__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $space-md;
    padding: $space-xs;
    background: $ui-gradiant-icon;
    border-radius: 50px;

    &--is-disabled {
      background: $ui-gradiant-icon-disabled;
    }
  }

  &__icon {
    color: $icon-white;
  }

  &__wrapper {
    &--is-disabled {
      opacity: 0.5;
    }
  }

  &__placement {
    display: flex;
    align-items: center;
    padding-bottom: $space-md;

    &__location {
      @include text("service/detail/placements/subTitle");
    }

    &__link {
      display: flex;
      align-items: center;
      margin-left: $space-md;

      &__icon {
        color: $text-primary;
      }

      &__label {
        display: flex;
        align-items: center;
      }

      &__text {
        @include text("service/detail/placements/link");
        padding-left: $space-xs;
      }
    }
  }

  &__meta {
    @include text("meta");

    &--is-highlighted {
      font-weight: 700;
    }
  }

  &__info {
    &__status__Accept {
      color: $text-success;
      font-weight: 700;
    }

    &__status__Reject {
      color: $text-error;
      font-weight: 700;
    }
  }

  &__content {
    display: block;
    width: calc(100% - $space-md);
    padding: 0 calc($space-2xl - 3px);
    white-space: pre-line;
    border-left: 3px solid $border-primary;

    &--is-disabled {
      border-left-color: $border-lightgray;
    }
  }

  &__description {
    @include text("meta");
  }

  &__expiresAt {
    @include text("meta");

    &__date {
      font-weight: 700 !important;
    }
  }

  &__button {
    margin-top: $space-xs !important;
    margin-right: $space-md !important;
  }
}
