@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".AdvancedFormFieldContainer";

#{$selector} {
  margin-bottom: $space-lg;

  &__description {
    @include text("meta");
    padding: $space-md;
    background-color: $background-lightgray;
    border-radius: 4px;
  }

  &--mb_requestedareawidthmin_float,
  &--mb_requestedareadepthmin_float {
    float: left;
    width: calc(50% - $space-xs);
    margin-right: $space-xs;
  }

  &--mb_requestedareadepthmin_float {
    margin-right: 0;
    margin-left: $space-xs;
  }
}
