@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Checkbox";

#{$selector} {
  &__helperText {
    margin-bottom: 0 !important;
    margin-left: $space-2xl !important;
  }
}
