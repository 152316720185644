@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".BookingDetailInfo";

#{$selector} {
  &__field__row {
    display: flex;
    margin-top: $space-lg;
    padding: $space-lg 0;
  }

  &__field {
    width: 25%;

    &__label,
    &__value {
      display: block;
    }
  }

  &__value {
    word-wrap: break-word;
  }
}
