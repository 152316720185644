@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Card";

#{$selector} {
  margin-bottom: $space-2xl;
  box-shadow: $ui-elevation-card !important;
}
