@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".BasicLayout";

#{$selector} {
  @include flex();
  min-height: 100vh;

  &__col {
    flex-basis: 100%;

    &:nth-of-type(2) {
      margin-top: $space-xl;
      padding-top: $space-lg;
      border-top: 1px solid $border-primary;

      @include screenLargerThan($screen-lg) {
        flex-basis: 60%;
        width: auto;
        margin-top: $space-2xl;
        margin-top: 0;
        margin-left: $space-lg;
        padding-top: 0;
        padding-left: $space-lg;
        border-top: none;
        border-left: 1px solid $border-primary;
      }

      @include screenLargerThan($screen-xl) {
        flex-basis: 30%;
      }
    }
  }

  &__card__title {
    @include text("component/documentBox/headline");
    display: block;
    margin-bottom: $space-lg;
  }

  &__card__content {
    display: block;

    & p {
      margin-bottom: 0 !important;
    }
  }

  main {
    @include text("base");
    flex: 1;
    padding: $space-xl;

    h1 {
      @include text("headline/2xl");
      margin-bottom: $space-xl;
    }

    h2 {
      @include text("headline/xl");
    }

    h3 {
      @include text("headline/lg");
    }

    h4 {
      @include text("headline/md");
    }

    h5 {
      @include text("headline/sm");
    }

    h6 {
      @include text("headline/xs");
    }

    strong {
      font-weight: 700;
    }

    em {
      font-style: italic;
    }

    pre {
      @include text("meta");
    }

    a {
      @include text("link");
      color: $text-primary;

      &:hover {
        text-decoration: underline;
      }
    }

    @include screenLargerThan($screen-lg) {
      display: flex;
      flex-direction: row;
    }

    @include screenLargerThan($screen-xl) {
      @include centered-container();
      width: $screen-xl;
    }
  }
}

.is-style-outline {
  color: $text-primary !important;
}
