@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Tabs";

#{$selector} {
  margin-bottom: $space-lg;

  &__tab {
    @include text("headline/xs");

    &__label {
      @include text("headline/xs");

      &__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &__info {
        @include text("component/tabs/infoLabel");
        margin-right: $space-lg;
        padding: $space-xs $space-md;
        border: 1px solid $border-error;
        border-radius: $ui-default-border-radius;
      }
    }
  }
}
