@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".NavigationDropdown";

#{$selector} {
  position: absolute;
  z-index: $layer-navigation-dropdown;
  padding: $space-lg;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  &__title {
    @include text("navigation/lg");
    padding-bottom: $space-lg;
    text-transform: uppercase;

    @include screenSmallerThan($screen-xl) {
      display: none;
    }
  }
}
