@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".TreeViewItem";

#{$selector} {
  &__label__wrapper {
    padding: $space-sm;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__content {
      padding-right: $space-md;
    }
  }
}
