@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Select";

#{$selector} {
  &__item__description {
    @include text("meta");
    display: block;
    white-space: normal;
  }
}
