// Color tokens reflected from the tokens in figma
// -----------------------------------------------------------------------------

// Backgrounds
$background-base: #ffffff !default;
$background-primary: var(--color-primary) !default;
$background-secondary: var(--color-secondary) !default;
$background-darkgray: #4d4d4d !default;
$background-gray: #c4c4c4 !default;
$background-lightgray: #f7f7f7 !default;
$background-success: #007e33 !default;
$background-warning: #ff8800 !default;
$background-error: #d32f2f !default;

// Text
$text-base: #4d4d4d !default;
$text-primary: var(--color-primary) !default;
$text-secondary: var(--color-secondary) !default;
$text-white: #ffffff !default;
$text-lightgray: #cccccc !default;
$text-success: #007e33 !default;
$text-error: #d32f2f !default;
$text-info: #4892d4 !default;

// Icons
$icon-base: #4d4d4d !default;
$icon-white: #ffffff !default;
$icon-primary: var(--color-primary) !default;
$icon-secondary: var(--color-secondary) !default;
$icon-error: #d32f2f !default;

// Borders
$border-base: var(--color-border) !default;
$border-primary: var(--color-primary) !default;
$border-white: #ffffff !default;
$border-lightgray: #cccccc !default;
$border-error: #d32f2f !default;

// Header
$ui-header-text: var(--color-header-text) !default;

// Navigation
$ui-navigation-text: var(--color-navigation-text) !default;

// Footer
$ui-footer-text: var(--color-footer-text) !default;

// Table
$ui-table-status-new: #4d4d4d;
$ui-table-status-pending: #4d4d4d;
$ui-table-status-canceled: #4d4d4d;
$ui-table-status-deferred: #4d4d4d;
$ui-table-status-internal: #4d4d4d;
$ui-table-status-released: #4d4d4d;
$ui-table-status-waiting: #4d4d4d;
$ui-table-status-checked: #4d4d4d;
$ui-table-status-placed: #4d4d4d;
$ui-table-status-proposalCreated: #4d4d4d;
$ui-table-status-proposalSent: #4d4d4d;
$ui-table-status-proposalRejected: #4d4d4d;
$ui-table-status-proposalAssumed: #4d4d4d;
$ui-table-status-support: #4d4d4d;
$ui-table-status-invoiceReady: #4d4d4d;
$ui-table-status-invoicing: #4d4d4d;
$ui-table-status-invoiceCreated: #4d4d4d;
$ui-table-status-incomingPayment: #4d4d4d;
$ui-table-status-closed: #4d4d4d;
